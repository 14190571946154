<template>
    <div class="container pb-12" v-if="asset">
        <button @click="$router.back()" class="btn btn-white btn-sm my-6 md:my-12 ml-3 md:ml-0">
            Voltar
        </button>
        <div class="item_details" id="musicDetails">
            <div class="row sm:space-y-8">
                <!-- primeira coluna -->
                <div class="col-lg-6">
                    <div style="position: relative;" >
                        <AssetTabNftVideo :asset="asset"   />
                        <!-- Banner -->
                        <div class="badge-overlay" v-if="asset.available_amount == 0">
                            <!-- Change Badge Position, Color, Text here-->
                            <span class="top-left badge orange">Esgotado</span>
                        </div>
                    </div>
                    
                </div>
                <!-- Segunda Coluna -->
                <div class="col-lg-6">
                    <!-- Banner com titulo, nome de usuário e botao ver certificado -->
                    <div class="space-y-2">
                        <div class="d-flex justify-content-between items-center">
                            <div class="hidden md:block ">
                                <div class="text-md font-bold lowercase" style="color: #919191;" v-if="asset.asset_type">{{ asset.asset_type.name }}</div>
                                <h3 class="text-6xl text-white font-bold">{{ asset.name }}</h3>
                                <div class="creators" style="margin-top: 12px" v-if="asset.owner">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p class="avatars_name text-primary text-xl"> {{ asset.owner.username }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3" >
                                    <div class="w-100" >
                                        <button type="button" class="botao-certificado mr-8" @click="abrirCertificado()">
                                            Ver Certificado
                                        </button> 
                                            <CertificateToDownload :asset="asset" />  
                                    </div>
                                </div>
                            </div>
                        </div>                      
                    </div>
                    <!-- Banner com as tabs de Detalhes e Atributos -->
                    <div class="box mt-6" style="background: transparent; border: none;padding: 0;">
                        <div class="space-y-4">
                            <div class="md:hidden mx-3">
                                <div class="text-md font-bold lowercase" style="color: #919191;" v-if="asset.asset_type">{{ asset.asset_type.name }}</div>
                                <h3 class="text-5xl text-white font-bold">{{ asset.name }}</h3>
                                <div class="creators" style="margin-top: 12px" v-if="asset.owner">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p class="avatars_name text-primary text-xl">{{ asset.owner.username }}</p>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                    <div class=" mb-6" >
                                        <button type="button" class="botao-certificado w-full" @click="abrirCertificado()">
                                            Ver Certificado
                                        </button>        
                                    </div>
                                    <div >   
                                        <CertificateToDownload :asset="asset" :screen="'mobile'"/>   
                                    </div>
                                </div>
                                </div>
                            </div>
                            <!-- tabs titles -->
                            <div class="d-flex justify-content-between mb-30_reset mx-3 md:mx-0 ">
                                <ul class="nav nav-tabs d-flex space-x-2 mb-30" role="tablist">
                                    <li class="nav-item">
                                        <a class="btn btn-white btn-sm active" data-toggle="tab" href="#tabs-1" role="tab">Detalhes</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="btn btn-white btn-sm" data-toggle="tab" href="#tabs-3" role="tab">Atributos</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="hr"></div>
                            <!-- conteúdo das tabs -->
                            <div class="tab-content mx-3 mb-3 md:mx-0 md:mb-0" v-if="asset">
                                <div class="tab-pane active" id="tabs-1" role="tabpanel">
                                    <p class="text-white break-words"><strong class="text-primary">Hash:</strong> {{ asset.hash }}</p>
                                    <div  v-if="composer">
                                    <p class="text-white"><strong class="text-primary">Compositor(es):</strong></p>
                                    <p v-for="(compositor, index) in composer" :key="index" class="text-white"> {{ compositor }}</p>
                                    </div>
                                    <p class="text-white"><strong class="text-primary">Data de Criação do NFT: </strong>{{ asset.created_at }}</p>
                                    <!-- <p class="text-white" v-if="asset.owner && asset.owner.username"><strong class="text-primary">
                                        Proprietário: </strong>
                                        {{ asset.owner.first_name }} {{ asset.owner.last_name }}
                                        </p> -->
                                    <p class="text-primary" v-if="asset.description!=''"><strong>Letra:</strong></p>
                                    <p class="text-white" v-html="asset.description" v-if="asset.description!=''"></p>
                                </div>
                                <div class="tab-pane" id="tabs-3" role="tabpanel">
                                    <ul class="grid grid-cols-1 md:grid-cols-3 gap-4">
                                        <li class="border-primary text-center rounded-md p-3" v-for="atributo in atributos" :key="atributo.trait_type">
                                            <div v-if="atributo.trait_type!='additional_files' ">
                                                <div class="row ">
                                                    <span class="text-primary font-bold uppercase text-sm break-words	" 
                                                >{{ atributo.trait_type }}:</span>
                                                </div>
                                                <div class="row">
                                                    <p class="text-white" > {{ atributo.value }}</p>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="row">
                                                <span class="text-primary font-bold uppercase text-sm" >Arquivo Adicional</span>
                                                </div>
                                                <div class="row">
                                                <a :href="atributo.value" >Visualizar Arquivo</a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</template>

<script>
import api from '@/api'
import router from '@/router';
import { mapGetters } from 'vuex'
import AssetTabNftVideo from '../../AssetDetails/AssetTabNftVideo.vue'
import CertificateToDownload from '../Certificate/CertificateToDownload.vue'

export default {
    name: 'Music Details',
    data() {
        return {
            
            asset: false,
            assetDetails: [],
            fromAddress: null,
            compositores: false,
        }
    },
    computed: {
        ...mapGetters(['user','wallet']),
        composer() {
            var arrayComposer = false;
            for (let index = 0; index < this.asset.attributes.length; index++) {
                const element = this.asset.attributes[index];
                if(element.trait_type==='composers') {
                    arrayComposer = element.value.split("????")
                }
            }
            return arrayComposer;
        },
        atributos() {
            return this.asset.attributes.filter(arr => arr.trait_type!='composers');
        }
    },
    methods: {
        async fetchAsset() {
            if(this.$route.params.id) {
                await api.get(`my-musics/${this.$route.params.id}`).then(response => {
                    this.asset = response
                    // console.log("🚀 ~ file: MusicDetails.vue ~ line 421 ~ awaitapi.get ~ this.asset", JSON.stringify(this.asset))
                }).catch(error => {
                    console.error(error)
                })
            }
        },
        getComposers() {
            for (let index = 0; index < this.asset.attributes.length; index++) {
                const element = this.asset.attributes[index];
                if(element.trait_type=='composers') {
                    this.compositores = element.value
                } 
            }
            if(this.compositores) {
                return this.compositores
            } else {
                return false
            }
        },
        // getAtributos() {
        //     this.atributos = this.asset.attributes;
        //     var idCompositores = this.asset.attributes.findIndex(index =>  index.trait_type=='composers');
        //     this.atributos.splice(idCompositores, 1)
        // },
        abrirCertificado() {
            router.push({ name: 'Certificado', params: { id: this.$route.params.id } })
        },
        baixarCertificado() {
            router.push({ name: 'CertificateToDownload', params: { id: this.$route.params.id } })
        }
    },
    // watch: {
    //     asset() {
    //         if(this.asset) {
    //            this.getComposers();
    //         //    this.getAtributos();
    //         }
    //     }
    // },
    components: {
    AssetTabNftVideo,
    CertificateToDownload,
},
    async created() {
        this.fetchAsset()
    },

}
</script>

<style>
.botao-certificado {
    border: 2px solid #01e769;
    padding: 1em 2em;
    color: white;
    font-size: 1em;
    font-weight: 500;
    transition-duration: 0.4s;
}

.botao-certificado:hover {
    background-color: #01e769; /* Green */
    color: black;
    
}

.bgb {
    background: #01e769
}
    .loader_2 {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(227 227 227 / 79%);
    display: flex;
    backdrop-filter: blur(6px);
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    text-transform: uppercase;
    color: #01E769;
    font-size: 30px;
    z-index: 99999;
    top: 0;
    left: 0;
}

.nav-tabs .nav-item a.active, .nav-tabs .nav-item a.active:focus {
    background: #01e769 !important;
    color: #191919 !important;
    border: none;
}
.nav-tabs .nav-item .btn-white {
    background: transparent;
    border: solid 1px rgba(10, 33, 65, 0.05);
    color: #01e769;
    box-shadow: 0 !important;
}
.badge-overlay {
    position: absolute;
    left: 0%;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 100;
    -webkit-transition: width 1s ease, height 1s ease;
    -moz-transition: width 1s ease, height 1s ease;
    -o-transition: width 1s ease, height 1s ease;
    transition: width 0.4s ease, height 0.4s ease
}

/* ================== Badge CSS ========================*/
.badge {
    margin: 0;
    padding: 0;
    color: white;
    padding: 10px 10px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    line-height: normal;
    text-transform: uppercase;
    background: #ed1b24;
}

.badge::before, .badge::after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0 -1px;
    width: 100%;
    height: 100%;
    background: inherit;
    min-width: 55px
}

.badge::before {
    right: 100%
}

.badge::after {
    left: 100%
}

/* ================== Badge Position CSS ========================*/
.top-left {
    position: absolute;
    top: 0;
    left: 0;
    -ms-transform: translateX(-30%) translateY(0%) rotate(-45deg);
    -webkit-transform: translateX(-30%) translateY(0%) rotate(-45deg);
    transform: translateX(-30%) translateY(0%) rotate(-45deg);
    -ms-transform-origin: top right;
    -webkit-transform-origin: top right;
    transform-origin: top right;
}
.badge.red {
    background: #ed1b24;
}
.modal-wallet-connect, #status-modal {
    position: fixed;
}
</style>
