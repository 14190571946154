<template>
    <button type="button" :class="'botao-certificado ' + adjust" @click="downloadPDF()">
        Baixar Certificado
    </button> 

    <!-- ESTE É APENAS PARA DOWNLOAD, NUM FORMATO PRA OCUPAR UMA PÁGINA SÓ -->
<div v-show="false">
    <div id="ban-2" class="banner-2 text-white" v-if="asset.id!=false" >
            <div class="grid grid-cols-1 grid-central" id="gridCentral">
                <div class="row primeito-titulo" style="color: #0cc4ed; width:100%; font-size: 1.8em; margin-bottom: 0.8em;">
                    Parabéns!
                </div>
                <div class="row primeiro-texto " style="font-size:0.9em; width:60%; margin-bottom: 0.7em;">
                    <span class="text-capitalize" style="padding:0;margin:0;">{{ asset.owner.first_name }} {{ asset.owner.last_name }},</span> 
                    O registro de sua obra está confirmado, 
                    garantindo a proteção de propriedade autoral com extrema segurança.
                </div>

                <div class="row text-lg" style="color: #0cc4ed;width:fit-content; font-size:1.1em; margin-bottom: 0.8em; ">
                    Seguem dados do seu registro:
                </div>

                <div class="row" style="font-size:0.9em; margin-bottom: 0.8em;" v-if="asset.name">
                    Música: {{ asset.name }}
                </div>

                <div class="row" style="font-size:0.9em; width:60%; margin-bottom: 0.8em;" v-if="composer">
                    Compositor(es): 
                    <p v-for="(compositor, index) in composer" :key="index" class="text-white" style="margin:0; padding:0;"> {{ compositor }} </p>
                </div>

                <div class="row" style="font-size:0.9em; width:60%; margin-bottom: 0.8em; " v-if="asset.address">
                    Endereço: {{ asset.address }}
                </div>

                <div class="row" style="font-size:0.9em;margin-bottom: 0.8em;">
                    <div class="grid gap-6 grid-cols-2 mb-6" style="width:24em;margin:0;padding:0;" >
                        <div v-if="dataFormatada">
                            Data: {{ dataFormatada }}
                        </div>
                        <div v-if="horaFormatada">
                            Hora: {{ horaFormatada }}
                        </div>
                    </div>
                </div>

                <div class="row" style="font-size:0.9em; margin-bottom: 0.8em;" v-if="asset.hash">
                    Hash ID: 
                   <p class="text-white p-0"> {{ asset.hash }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
    name: 'CertificateToDownload',
    props: ['asset', 'screen'],
    data() {
        return {

            compositores: false,
            address: false,
            dataFormatada: false,
            horaFormatada: false,
        }
    },
    computed: {
        composer() {
            var retorno = false;
            for (let index = 0; index < this.asset.attributes.length; index++) {
                const element = this.asset.attributes[index];
                if(element.trait_type==='composers') {
                    retorno= element.value.split("????")
                }
            }
            return retorno;
        },
        adjust() {
            if(this.screen=='mobile') {
                return 'w-full';
            } else  {
                return '';
            }
        }
    },

    methods: {
        formatarDataHora() {
            const vetor = this.asset.created_at.split(" ")
            this.dataFormatada = vetor[0]
            this.horaFormatada = vetor[1]
        },
        downloadPDF() {
            html2pdf(document.getElementById("ban-2"), {
                image: { type: 'jpeg', quality: 1 },
                html2canvas:  { scale: 4, letterRendering: true, useCORS: true },
                filename: "Certificado.pdf",
                jsPDF: { unit: 'em', format: [70,60], orientation: 'p' }
            });
        },


    },
    // watch: {
    //     asset(){
    //         if(this.asset) {
    //             // this.getComposers();
    //             this.formatarDataHora();
    //         }
    //     }
    // },
}
</script>

<style scoped>

.botao-certificado {
    border: 2px solid #01e769;
    padding: 1em 2em;
    color: white;
    font-size: 1em;
    font-weight: 500;
    transition-duration: 0.4s;
}

font-face {
    font-family: Nexa;
    src: url('../../../../public/assets/fonts/NexaRegular.woff');
}

* {
    font-family: 'Nexa' !important;
}

.container {
    padding: 0 !important;
}   

.banner-2 {
    background-image: url('../../../assets/Certificado-registro-musica.png');
    background-color: #191919;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 70em;
}

.grid-central {
    padding-left: 8%;
    padding-top: 40%;
}

.primeiro-texto {
    width:22vmin;
}

p::first-letter {
    text-transform:capitalize;
}

</style>