<template>
    <div class="d-flex justify-content-between mb-30_reset" v-if="asset.youtube_url"> <!-- Mostra se tiver lin do youube -->
        <ul class="nav nav-tabs d-flex space-x-2 mb-30" role="tablist">
            <li class="nav-item">
                <a class="btn btn-white btn-sm " :class="{active: showNft}" role="tab" @click="openNftTab" >NFT</a>
            </li>
            <li class="nav-item">
                <a class="btn btn-white btn-sm" :class="{active: showVideo}" role="tab" @click="openVideoTab" >Vídeo</a>
            </li>
        </ul>
    </div>
    <br>
    <!-- <div class="hr" v-if="asset.youtube_url"></div> -->
    <br>
    <div v-show="showVideo">
        <div class="embed">
            <iframe :src="video_url_formatada" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
    <div v-show="showNft" > 
        <!--img :src="asset.thumbnail" v-if="!asset.file.includes('.mp4')" class="rounded-2xl mb-4" alt="">
        <audio v-if="asset.file && asset.file.includes('.mp3')" controls class="w-full md:w-2/3 mb-2" style="border: 2px solid #01E769;border-radius: 30px;">
            <source :src="asset.file">
        </audio>
        <video v-if="asset.file && asset.file.includes('.mp4')" controls class="w-full mb-2" style="border: 2px solid #01E769;border-radius: 30px;">
            <source :src="asset.file">
        </video-->
        <vue-plyr v-if="asset.file && asset.file.includes('.mp3')">
            <video controls :poster="asset.thumbnail">
                <source :src="asset.file" type="audio/mp3">
            </video>
        </vue-plyr>
        <div v-else-if="asset.file && asset.file.includes('.mp4')">
        <video  controls class="w-full mb-2" style="border: 2px solid #01E769;border-radius: 30px;">
            <source :src="asset.file">
        </video>
        </div>
        <div v-else-if="asset.file && asset.file.includes('.wav')">
        <video controls :poster="asset.thumbnail" class="w-full mb-2" style="border: 2px solid #01E769;border-radius: 30px;">
            <source :src="asset.file">
        </video>
        </div>
        <div v-else-if="!asset.file.includes('.mp4')" class="grid justify-items-end">
            <img :src="asset.thumbnail" class="rounded-2xl mb-1 " alt="" id="imagem" >
            <a data-toggle="tooltip" title="Fullscreen">
            <button @click="fullscreen" class=" h-10 w-10 py-2 px-2 btn-sm button-fullscreen rounded-full " >
                <i class="fa fa-expand text-sm"></i>
            </button>
            </a>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'AssetTabVideo',
    props: {
        asset: Object,
    },
    data() {
        return {
            showVideo: false,
            showNft: true,
            video_url_formatada: null,
        }
    },
    methods: {
        openNftTab() {
            this.showNft=true;
            this.showVideo=false;
        },
        openVideoTab() {
            this.showNft=false;
            this.showVideo=true;
        },
        formatVideo() {
            if(this.asset.youtube_url.includes(".com/watch?v")) {
                const vector = this.asset.youtube_url.split(".com/watch?v=");
                this.video_url_formatada = vector[0] + '.com/embed/' + vector[1];   
            } else if (this.asset.youtube_url.includes("youtu.be/")) {
                const vector = this.asset.youtube_url.split("youtu.be/");
                this.video_url_formatada = "https://www.youtube.com/embed/" + vector[1]
            }
        },
        fullscreen() {
            var el = document.getElementById('imagem');
            el.requestFullscreen();
        }

    },
    created() {
        if(this.asset.youtube_url){
            this.formatVideo()
        }
        
    },

}
</script>

<style scoped>
.embed {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 56.25%; 
    
}

.embed iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    padding-bottom: 0;
}

.button-fullscreen {
    color:lightgreen;
    border: 1px solid white;
    margin: 0;
    
    transition: 1s;

}

.button-fullscreen:hover {
    border: 1px solid #01e769;
    background: #4c7848 !important;
    color: #01e769 !important;
    border: 1px solid #4c7848;
}
</style>